import constants from "@/utils/constants";
import exportFromJSON from "export-from-json";
import * as BrowserImageResizer from "browser-image-resizer";
import ImageServices from "@/services/image.service";
import TradeInService from "@/services/trade-in.service";
import EquipmentServices from "@/services/equipment.services";
import {EventBus} from "@/event-bus";
import Constants from "@/utils/constants";

const configForImageResize = {
    quality: 0.8,
    maxWidth: 800,
    maxHeight: 600,
    debug: true
};
export default {
    appendBaseUrl(name) {
        let url;
        if (constants.environment === "local") {
            url = constants.baseURL.local.replace("api/", "") + name.replace('client/', "");
        } else if (constants.environment === "production") {
            url = constants.baseURL.production.replace("api/", "") + name;
        } else if (constants.environment === "qa") {
            url = constants.baseURL.qa.replace("api/", "") + name;
        } else {
            url = this.$baseUrl.replace("api/", "") + name;
        }
        return url;
    },
    formatImageName(imageUrl) {
        if (imageUrl.indexOf("/") > -1) {
            const path = imageUrl.split("/");
            const name = path[path.length - 1];
            let short = name.split("_")[name.split("_").length - 1];
            if (short.length > 20)
                return "..." + short.substring(short.length - 15);
            else return short;
        } else {
            if (imageUrl.length > 20)
                return "..." + imageUrl.substring(imageUrl.length - 15);
            else return imageUrl;
        }
    },
    generateConstructionYearSpan() {
        const constructionYears = [{text: "Beliebig", value: undefined}];
        const max = new Date(Date.now()).getFullYear();
        const min = new Date('1970').getFullYear();
        for (let el = max; el >= min; el--)
            constructionYears.push({text: el, value: el});
        return constructionYears;
    },
    generateLiftingHeightSpan() {
        const liftingHeight = [...constants.liftingHeights];
        // 10000...12000
        const values = [
            {
                text: "10.500 mm",
                value: 10500
            },
            {
                text: "11.000 mm",
                value: 11000
            },
            {
                text: "11.500 mm",
                value: 11500
            },
            {
                text: "12.000 mm",
                value: 12000
            },
        ];
        liftingHeight.push(...values);
        return liftingHeight;
    },
    proofExistenceOfValue(value) {
        if (typeof value === "string")
            return value && value.length > 0;
    },
    mapStatus(status) {
        switch (status) {
            case 'inProgress':
                return 'In Bearbeitung';
            case 'completed':
                return 'Abgeschlossen';
            default:
                return 'Offen';
        }
    },
    mapCustomerIntend(intend) {
        switch (intend) {
            case 'lease':
                return 'Leasing';
            case 'finance':
                return 'Finanzierung (Mietkauf)';
            default:
                return 'Kauf';
        }
    },
    formatNumbers(number) {
        if (!number) return;
        return number.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
    },
    formatDate(value) {
        if (!value) return "";
        value = new Date(value);
        const d = value.getDate() >= 10 ? value.getDate() : "0" + value.getDate();
        const m =
            value.getMonth() + 1 >= 10
                ? value.getMonth() + 1
                : "0" + (value.getMonth() + 1);
        const y = value.getFullYear();
        return d + "." + m + "." + y;
    },
    formatDateTime(value) {
        const date = this.formatDate(value);
        if (date === "") return "-";
        value = new Date(value);
        const h = value.getUTCHours() >= 10 ? value.getUTCHours() : "0" + value.getUTCHours();
        const m = value.getUTCMinutes() >= 10 ? value.getUTCMinutes() : "0" + value.getUTCMinutes();
        return date + " " + h + ":" + m;
    },
    isReservedActive(date) {
        if (!date) return false;
        const today = new Date(Date.now());
        const reservedUntil = new Date(date);
        return today < reservedUntil;
    },
    checkValueForNonEmptyArray(value) {
        if (value && typeof value === "object") {
            if (value.length > 0) {
                if (value.indexOf(",") > -1) {
                    return value.split(",");
                } else {
                    return value;
                }
            }
            return value[0];
        } else if (typeof value === "string" && value.length > 0) {
            if (value.indexOf(",") > -1) {
                return value.split(",");
            } else {
                return [value];
            }
        }
        return [];
    },
    removeDuplicatesInArray(data) {
        if (!data) {
            return []
        }
        if (typeof data === "string") {
            data = data.split(',')
        }

        const cleanedArray = data.map(str => str.replace(/\s/g, "").trim());
        const uniqueStrings = [];
        const seen = {};

        for (let str in cleanedArray) {
            if (!seen[cleanedArray[str]]) {
                uniqueStrings.push(data[str])
                seen[cleanedArray[str]] = true
            }
        }
        return uniqueStrings.toString()
    },
    countNumberOfActiveEquipments(equipments) {
        return this.getActiveElements(equipments).length;
    },
    getActiveElements(equipments) {
        const activeElements = equipments.filter(eq => (eq.active == 0 || eq.active == null) && !this.isReservedActive(eq.reservedUntil) && eq.trashed != 1);
        return activeElements;
    },
    getUntrashedElements(equipments) {
        const untrashedElemens = equipments.filter(eq => (eq.trashed != 1));
        return untrashedElemens;
    },
    isFavorite(equipmentId) {
        return !!this.$store.state.favorites.find(f => f.equipmentId === equipmentId);
    },
    isCompare(equipmentId) {
        return !!this.$store.state.compares.find(f => f.equipmentId === equipmentId);
    },
    formatEmptyValue(value) {
        return value ? value : '-';
    },
    getDescriptionFontSize() {
        if (window.innerWidth < 600) return 12;
        else return 14;
    },
    exportEquipmentFromJSON(data, newFileName) {
        if (!data) return;
        try {
            const fileName = newFileName || "exported-data";
            const exportType = exportFromJSON.types["csv"];
            exportFromJSON({data, fileName, exportType});
        } catch (e) {
            throw new Error("Parsing failed!");
        }
    },
    resizeImageAndUpload(auth, objId, file, name, useType, definitionType, config = configForImageResize) {
        BrowserImageResizer.readAndCompressImage(file, config)
            .then(resizedImage => {
                // Upload file to some Web API
                const formData = new FormData();
                if (useType === "equipment") {
                    formData.append('images', resizedImage, "w800_" + objId + '_' + name);
                    ImageServices.addImages(auth, objId, formData);
                } else if (useType === "rTruck") {
                    formData.append('images', resizedImage, "w800_rt_" + objId + '_' + name);
                    formData.append('definitionType', definitionType);
                    ImageServices.uploadReturnTruckImages(auth, objId, formData);
                } else if (useType === "tradeIn") {
                    formData.append('images', resizedImage, "w800_" + name);
                    TradeInService.uploadTradeInImage(objId, formData);
                } else if (useType === "contact") {
                    formData.append('image', resizedImage, "cp_" + name);
                    ImageServices.uploadContactPersonImage(auth, objId, formData);
                }
            })
    },
    formatLightsInformation(data, type) {
        if (!data) return;
        if (type === 'read') {
            let outputData = [];
            outputData = data;
            // returns ASW 1/2/3, ....
            // ASW 1, ASW 2, ASW 3, .....
            const iLight = data.findIndex(l => l.startsWith('ASW'));
            // outputData.splice(iLight, 1);
            // [ASW 1/2/4, ...., ... ]
            if (iLight === -1) return;

            const lights = data[iLight].replace('ASW ', '').split('/').map(a => 'ASW ' + a);
            // [ASW 1, ASW 2, ASW 3, ...]
            outputData.push(...lights);
            outputData.splice(iLight, 1);
        } else {
            // returns ASW 1/2/3, ....
            const originalLights = data.filter(l => !l.startsWith('ASW'));
            let lights = data.filter(l => l.startsWith('ASW'));
            if (lights.length !== 0) {
                lights = lights.map(l => l.replace('ASW ', '')).sort().join('/');
                originalLights.push("ASW " + lights);
            }
            originalLights.join(', ');
            return originalLights;
        }
    },
    getEmptyContactPerson() {
        return {
            firstName: null,
            lastName: null,
            careerLevel: "Beratung & Verkauf",
            email: null,
            mobilePhone: null,
            phone: null,
            zip: null,
            image: null
        }
    },
    getEmptyEquipment() {
        return {
            additionalConstructionType: "",
            additionalHydraulic: [],
            attachmentUnit: [],
            availability: "",
            battery: "",
            charger: "",
            component: "",
            constructionType: "",
            constructionYear: "",
            deviceStatus: "",
            driversModule: [],
            driversPositionEquipment: [],
            driversSeat: "",
            energyPack: [],
            freeLift: "",
            height: null,
            image: "",
            imagesReal: [],
            liftingHeight: null,
            light: [],
            location: "UTS Center, Bollenheide 4, 42781 Haan",
            loadCapacity: null,
            mastType: "",
            merchantCode: "",
            operation: "",
            operationAndPedal: [],
            operatingHours: "",
            other: "",
            price: null,
            propulsion: "",
            providerProductNumber: "",
            safety: [],
            sampleImage: false,
            serialNumber: "",
            surrounding: [],
            tradeIn: false,
            tires: [],
            type: ""
        }
    },
    getEmptyReturnTruck() {
        return {
            manufacturer: "",
            chassisNumber: "",
            aProdId: "",
            secondLifeDecision: "",
            category: "",
            costEstimate: false,
            recordingPerson: "",
            inStock: false,
            status: "",
            description: "",
            constructionYear: "",
            images: [],
            imagesReal: [],
            operatingHours: "",
            cType: "",
            configurations: [],
            isDamaged: false,
        }
    },
    initiateSuccessInfo(msg, disableLoading = false) {
        if (!disableLoading)
            this.$store.commit("loading", false);
        this.$store.dispatch("showSnackbar", {
            color: "success",
            message: msg
        });
    },
    loading(state) {
        this.$store.commit("loading", state);
    },
    initiateErrorInfo(msg) {
        this.$store.dispatch("showSnackbar", {
            color: "error",
            message: msg
        });
    },
    setFavorite(equipment, comment) {
        EquipmentServices.getEquipmentByIdForUnauthorized(equipment.equipmentId)
            .then((response) => {
                response.data.comment = comment
                this.$store.commit("updateFavorites", response.data);
                this.favorite = this.isFavorite(response.data.equipmentId);
                const message = this.favorite ? "Stapler wurde zur Merkliste hinzugefügt!" : "Stapler wurde von Merkliste entfernt!"
                this.initiateSuccessInfo(message)
                EventBus.$emit("onCloseCommentDialog")
            })
    },
    reloadEquipmentsWithDelay(delay) {
        setTimeout(() => {
            this.$store.dispatch("getEquipmentData")
                .then(() => {
                    this.loading(false);
                })
                .catch(err => {
                    throw err;
                });
        }, delay);
    },
    clearFavoritesAndComparesFromDeletedItems(toCompare) {
        const activeElements = this.getActiveElements(this.$store.state.equipments)
        for (const f of toCompare.favorites) {
            const activeFavIndex = activeElements.findIndex(e => e.equipmentId === f.equipmentId);
            if (activeFavIndex === -1) {
                this.$store.commit("updateFavorites", f)
            }
        }
        for (const c of toCompare.compares) {
            const activeComIndex = activeElements.findIndex(e => e.equipmentId === c.equipmentId);
            if (activeComIndex === -1) {
                this.$store.commit("updateCompares", c)
            }
        }
    },
    base64toBlob(base64str) {
        base64str = base64str.split(",")[1];
        // decode base64 string, remove space for IE compatibility
        var binary = atob(base64str.replace(/\s/g, ""));
        // get binary length
        var len = binary.length;

        // create ArrayBuffer with binary length
        var buffer = new ArrayBuffer(len);

        // create 8-bit Array
        var view = new Uint8Array(buffer);

        // save unicode of binary data into 8-bit Array
        for (var i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
        }

        // create the blob object with content-type "application/pdf"
        return new Blob([view], {type: "application/pdf"});
    },
    setYesOrNo(prop) {
        return prop ? "Ja" : "Nein";
    },
    mapStatusOfReturnTruck(status) {
        if (!status) return ""
        return Constants.secondLifeDecisionItems.find(rts => rts.value === status).text;
    },
    getColorClass(status) {
        if (!status) status = "open"
        const normalizedStatus = status.toLowerCase(); // Normalize the input
        switch (normalizedStatus) {
            case "in_process":
            case "inprogress":
                return "yellow--text";
            case "completed":
                return "green--text";
            case "open":
                return "blue--text";
            default:
                return "grey--text";
        }
    },
    filteredAndSortedImages(images, equipment) {
        const typeOrder = ['product_front_left', 'product_front_right', 'product_back_left',
            'product_back_right', 'product_hour_counter', 'product_type'];
        return images
            .filter(i => i.equipmentId === equipment.equipmentId) // Filter by matching id
            .sort((a, b) => {
                const orderA = typeOrder.indexOf(a.type);
                const orderB = typeOrder.indexOf(b.type);

                if (orderA !== -1 && orderB !== -1) return orderA - orderB;
                if (orderA !== -1) return -1;
                if (orderB !== -1) return 1;
                return 0;
            });
    },
}
